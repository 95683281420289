<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <img :src="bannerImg" alt="" class="picture" />
      <div class="box">
        <div class="tipOne">{{ $t("RDconditions.RD_tipOne") }}</div>
        <div class="tipTwo">{{ $t("RDconditions.RD_tipTwo") }}</div>
        <div>
          <img src="@/assets/img/conditions.png" alt="" class="conditions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：技术研发-研发条件
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-17 17:02
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "技术研发",
          title_en: "Technology R & D",
          path: "TechnologyRD",
        },
        {
          title_zh: "研发条件",
          title_en: "R & D conditions",
          path: "",
        },
      ],
      bannerImg: "",
    };
  },
  mounted() {
    this.getBigPicture();
  },
  methods: {
    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-25
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgTwo",
        data: {
          type: 4, // 类型:1=人才培养,2=研发服务,3=专利服务,4=研发条件,5=科研成果,6=研发方向,7=部门设置
        },
      }).then((res) => {
        console.log("大图", res);
        this.bannerImg = res.img;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 0 260px 22px;
  background: #f6f6f6;
}

.picture {
  width: 100%;
  height: 400px;
  margin-bottom: 80px;
}

.box {
  padding: 0 50px;

  .tipOne {
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #1a2a60;
    margin-bottom: 60px;
  }

  .tipTwo {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    line-height: 32px;
    margin-bottom: 52px;
  }

  .conditions {
    width: 873px;
    height: 1298px;
    margin: 0 auto;
  }
}
</style>